export const allDimensionColumns = [
  {
    title: '日期',
    dataIndex: 'date',
    width: 140,
    align: 'left',
    fixed: false,
    sorter: false,
    sortOrder: undefined,
    key: 1
  },
  {
    title: '应用',
    dataIndex: 'appName',
    width: 120,
    align: 'left',
    fixed: false,
    sorter: false,
    sortOrder: undefined,
    scopedSlots: { customRender: 'appName' },
    key: 2
  },
  {
    title: '广告位',
    dataIndex: 'placeName',
    width: 140,
    align: 'left',
    fixed: false,
    sorter: false,
    sortOrder: undefined,
    key: 3
  },
  {
    title: '广告样式',
    dataIndex: 'positionName',
    width: 140,
    align: 'left',
    fixed: false,
    sorter: false,
    sortOrder: undefined,
    key: 4
  },
  {
    title: '广告平台',
    dataIndex: 'platName',
    width: 140,
    align: 'left',
    fixed: false,
    sorter: false,
    sortOrder: undefined,
    key: 5
  },
  {
    title: '系统类型',
    dataIndex: 'os',
    width: 140,
    align: 'left',
    fixed: false,
    sorter: false,
    sortOrder: undefined,
    scopedSlots: { customRender: 'os' },
    key: 6
  }
]

export const allTagColumns = [
  {
    title: 'DAU',
    dataIndex: 'dau',
    width: 120,
    align: 'left',
    fixed: false,
    sorter: false,
    sortOrder: undefined,
    label: 'DAU',
    checked: true, // 是否选中
    prop: 'dau', // 属性
    default: true, // 是否为默认
    type: 'GAP', // 分组种类
    key: 7
  },
  {
    title: '流量请求',
    dataIndex: 'request',
    width: 120,
    align: 'left',
    fixed: false,
    sorter: false,
    sortOrder: undefined,
    label: '流量请求',
    checked: true, // 是否选中
    prop: 'request', // 属性
    default: true, // 是否为默认
    type: 'GAP', // 分组种类
    key: 8
  },
  {
    title: '流量填充',
    dataIndex: 'response',
    width: 120,
    align: 'left',
    fixed: false,
    sorter: false,
    sortOrder: undefined,
    label: '流量填充',
    checked: true, // 是否选中
    prop: 'response', // 属性
    default: true, // 是否为默认
    type: 'GAP', // 分组种类
    key: 9
  },
  {
    title: '流量填充率',
    dataIndex: 'requestFilledRate',
    width: 120,
    align: 'left',
    fixed: false,
    sorter: false,
    sortOrder: undefined,
    scopedSlots: { customRender: 'requestFilledRate' },
    label: '流量填充率',
    checked: true, // 是否选中
    prop: 'requestFilledRate', // 属性
    default: true, // 是否为默认
    type: 'GAP', // 分组种类
    key: 10
  },
  {
    title: '展示',
    dataIndex: 'unitImpression',
    width: 120,
    align: 'left',
    fixed: false,
    sorter: false,
    sortOrder: undefined,
    label: '展示',
    checked: true, // 是否选中
    prop: 'unitImpression', // 属性
    default: true, // 是否为默认
    type: 'GAP', // 分组种类
    key: 11
  },
  {
    title: '展示率',
    dataIndex: 'unitImpressionRate',
    width: 120,
    align: 'left',
    fixed: false,
    sorter: false,
    sortOrder: undefined,
    scopedSlots: { customRender: 'unitImpressionRate' },
    label: '展示率',
    checked: true, // 是否选中
    prop: 'unitImpressionRate', // 属性
    default: true, // 是否为默认
    type: 'GAP', // 分组种类
    key: 12
  },
  {
    title: '点击',
    dataIndex: 'unitClick',
    width: 120,
    align: 'left',
    fixed: false,
    sorter: false,
    sortOrder: undefined,
    label: '点击',
    checked: true, // 是否选中
    prop: 'unitClick', // 属性
    default: true, // 是否为默认
    type: 'GAP', // 分组种类
    key: 13
  },
  {
    title: '点击率',
    dataIndex: 'unitClickRate',
    width: 120,
    align: 'left',
    fixed: false,
    sorter: false,
    sortOrder: undefined,
    scopedSlots: { customRender: 'unitClickRate' },
    label: '点击率',
    checked: true, // 是否选中
    prop: 'unitClickRate', // 属性
    default: true, // 是否为默认
    type: 'GAP', // 分组种类
    key: 14
  },
  {
    title: 'eCPM',
    dataIndex: 'unitEcpm',
    width: 120,
    align: 'left',
    fixed: false,
    sorter: false,
    sortOrder: undefined,
    label: 'eCPM',
    checked: true, // 是否选中
    prop: 'unitEcpm', // 属性
    default: true, // 是否为默认
    type: 'GAP', // 分组种类
    key: 15
  },
  {
    title: '收益',
    dataIndex: 'mediaUnitRevenue',
    width: 120,
    align: 'left',
    fixed: false,
    sorter: false,
    sortOrder: undefined,
    label: '收益',
    checked: true, // 是否选中
    prop: 'mediaUnitRevenue', // 属性
    default: true, // 是否为默认
    type: 'GAP', // 分组种类
    key: 16
  },
  {
    title: '人均收益',
    dataIndex: 'arpu',
    width: 120,
    align: 'left',
    fixed: false,
    sorter: false,
    sortOrder: undefined,
    label: '人均收益',
    checked: true, // 是否选中
    prop: 'arpu', // 属性
    default: true, // 是否为默认
    type: 'GAP', // 分组种类
    key: 17
  }
]
